<template>
  <div class="text-right">
    <!-- <div class="welcome ">{{$t('slide1.mine_admin_hi')}}</div>
    <div class="inquiry">{{$t('slide1.mine_admin_help_tips')}}</div> -->
    <div class="content" v-html="mine_admin"></div>

    <!-- <div style="width: 100%;text-align: center;">
      <van-button style="margin: 0 5px;padding: 0 30px;" v-for="(item, index) in langs" :key="index" type="info" @click="lang(index)">{{item}}</van-button>
    </div> -->
  </div>
</template>

<script>
import $ from "jquery";
import { Toast } from "vant";
import axios from "axios";
import Cookies from 'js-cookie';
export default {
  name: "App",
  components: {
    // HelloWorld
  },
  data() {
    return {
      userinfodata: null,
      total: null,
      mine_admin: '',
    };
  },
  created() {
    let payment_url = location.hash
    let askIndex = payment_url.indexOf('?');
    var newStr = payment_url.slice(askIndex);
    var theRequest = new Object();
    if (newStr.indexOf('?') != -1) {
      var str = newStr.substr(1);
      let strs = str.split('&');
      for (var i = 0; i < strs.length; i++) {
        theRequest[strs[i].split('=')[0]] = strs[i].split('=')[1];
      }
    }
    let langs = theRequest.langType.substring(0, 2)
    let lang = theRequest.langType
    if (langs == 'zh') {
        if (lang == 'zh_TW' || lang == 'zh_HK') {
          this.$i18n.locale = 'tc'
        }
        else {
          this.$i18n.locale = 'zh'
        }
      }
      else if (langs == 'en') {
        this.$i18n.locale = 'en'
      }
      else if (langs == 'ar') {
        this.$i18n.locale = 'ar'
      }
      else if (langs == 'tr') {
        this.$i18n.locale = 'tr'
      }
      else if (langs == 'pt') {
        this.$i18n.locale = 'pt'
      }
      else if (langs == 'es') {
        this.$i18n.locale = 'es'
      }
      else {
        this.$i18n.locale = 'en'
      }
    document.querySelector('html').setAttribute('lang', this.$i18n.locale);
    document.title = this.$t('slide1.title')
    this.helplist()
  },
  mounted() {


  },

  methods: {

    helplist() {
      let paramObj = {
        lang: this.$i18n.locale == 'zh' ? 'cn' : this.$i18n.locale,
      };
      this.$server.privacy(paramObj).then((data) => {
        this.mine_admin = data.data.content;
      });
    },

    //调取原生方法
    getUserInformation(data) {
      this.userinfodata = data;
      let id = JSON.parse(data);
      axios.defaults.baseURL = id.serverUrl; //请求地址

    },

  },

};

</script>

<style scoped>
/* html[lang="ar"] .text-right {
  text-align: right;
}

html[lang="zh"] .text-left {
  text-align: left;
} */
html[lang="ar"] .content {
  font-size: 0.237rem;
  font-family: SF Pro;
  font-weight: 400;
  color: #707480;
  line-height: 0.338rem;
  padding: 0.25rem 0;
  direction: rtl;
}

.content {
  font-size: 0.237rem;
  font-family: SF Pro;
  font-weight: 400;
  color: #707480;
  line-height: 0.338rem;
  padding: 0.25rem 0;
}

.content > p {
  margin: 0 !important;
}
</style>